@import 'src/assets/scss/variables';

.landing_page_header_container {
  width: 100%;
  z-index: 999;
  position: fixed;
  transition: top 0.3s;
  height: $header-height;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  @media (max-width: 750px) {        
      height: fit-content;        
  }

  .landing_page_header_main_content {
    margin: auto;
    display: grid;
    height: 100%;
    padding: 10px;
    align-items: center;
    background-color: #fff;
    grid-template-columns: auto 1fr;
    max-width: $landing-page-container-max-width;
    grid-template-areas: 'logo navigation navigation right_content';   

    .logo_container {
      grid-area: logo;
    }    

    .navigation {
      grid-area: navigation;      
      grid-template-columns: 1fr;

      @media (max-width: 750px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        display: inline-grid;
       }    
    }
    .right_content {
      display: flex;
      align-items: center;
      grid-area: right_content;
    }
  }

  
}