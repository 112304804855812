.web_menu_navigation {
  .ant-menu-horizontal {
    border-bottom: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* width */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.navigationLinkItem {  
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
}
